import Categories from "./Categories";
import SearchBy from "./SearchBy";

function Filters({
  isLive,
  isDarkTheme,
  queryName,
  queryProvider,
  categories,
  providers,
  setQueryName,
  setCurrentPage,
  setQueryCategory,
  setQueryProvider,
}) {
  return (
    <>
      <SearchBy
        queryName={queryName}
        isDarkTheme={isDarkTheme}
        setQueryName={setQueryName}
        setQueryProvider={setQueryProvider}
        queryProvider={queryProvider}
        providers={providers}
      />
      <Categories
        isLive={isLive}
        isDarkTheme={isDarkTheme}
        categories={categories}
        queryProvider={queryProvider}
        setCurrentPage={setCurrentPage}
        setQueryCategory={setQueryCategory}
      />
    </>
  );
}

export default Filters;
