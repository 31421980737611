import { useState } from 'react'
import { Button, Table } from 'react-bootstrap'

import CommissionsModal from '../modals/CommissionsModal'
import { formatNumber } from '../../utils'

export default function Cashier({
  isDarkTheme,
  type,
  data
}) {
  const [showModal, setShowModal] = useState(false)

  const renderTable = () => {
    if (type === 'geral')
      return (
        <>
          <tr>
            <td>Apuração</td>
            <td>R$ {formatNumber(data.valor_apostas)}</td>
          </tr>
          <tr>
            <td>Qtd. Apostas</td>
            <td>{data.apostas}</td>
          </tr>
          <tr>
            <td>Qtd. Ganhadores</td>
            <td>{data.ganhadores}</td>
          </tr>
          <tr>
            <td>Qtd. Apostas abertas</td>
            <td>{data.entradas_abertas}</td>
          </tr>
          <tr>
            <td>Entradas em aberto</td>
            <td>R$ {formatNumber(data.total_entradas_abertas)}</td>
          </tr>
          <tr>
            <td>Prêmios</td>
            <td>R$ {formatNumber(data.premios)}</td>
          </tr>
          <tr>
            <td>Comissões (pré-jogo)</td>
            <td>R$ {formatNumber(data.comissoes)}</td>
          </tr>
          <tr>
            <td>Comissões (ao vivo)</td>
            <td>R$ {formatNumber(data.comissoes_ao_vivo)}</td>
          </tr>
          <tr>
            <td>Comissões (geral)</td>
            <td>R$ {formatNumber(data.comissoes_geral)}</td>
          </tr>
          <tr>
            <td>Saldo Líquido</td>
            <td>R$ {formatNumber(data.saldo_liquido)}</td>
          </tr>
          <tr>
            <td>Lançamentos</td>
            <td>R$ {formatNumber(data.creditos)}</td>
          </tr>
          <tr>
            <td>Retiradas</td>
            <td>R$ {formatNumber(data.debitos)}</td>
          </tr>
          <tr>
            <td>Saldo Caixa</td>
            <td>R$ {formatNumber(data.saldo_caixa)}</td>
          </tr>
        </>
      )

    if (type === 'ao_vivo')
      return (
        <>
          <tr>
            <td>Apuração</td>
            <td>R$ {formatNumber(data.valor_apostas)}</td>
          </tr>
          <tr>
            <td>Qtd. Apostas</td>
            <td>{data.apostas}</td>
          </tr>
          <tr>
            <td>Qtd. Ganhadores</td>
            <td>{data.ganhadores}</td>
          </tr>
          <tr>
            <td>Qtd. Apostas abertas</td>
            <td>{data.entradas_abertas}</td>
          </tr>
          <tr>
            <td>Entradas em aberto</td>
            <td>R$ {formatNumber(data.total_entradas_abertas)}</td>
          </tr>
          <tr>
            <td>Prêmios</td>
            <td>R$ {formatNumber(data.premios)}</td>
          </tr>
          <tr>
            <td>Comissões</td>
            <td>R$ {formatNumber(data.comissoes)}</td>
          </tr>
          <tr>
            <td>Saldo Líquido</td>
            <td>R$ {formatNumber(data.saldo_liquido)}</td>
          </tr>
        </>
      )

    return null
  }

  const renderCommissions = () => {
    if (type === 'geral' && data.minhas_comissoes)
      return (
        <>
          <Button variant="secondary" block onClick={() => setShowModal(true)}>
            Ver Comissões
          </Button>
          <CommissionsModal
            show={showModal}
            commissions={data.minhas_comissoes}
            isDarkTheme={isDarkTheme}
            onHide={() => setShowModal(false)}
          />
        </>
      )

    return null
  }

  return (
    <>
      <Table variant={isDarkTheme ? 'dark' : '' } striped className="rounded mt-3">
        <tbody>
          {renderTable()}
        </tbody>
      </Table>
      {renderCommissions()}
    </>
  )
}
