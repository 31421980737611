import { useEffect, useState, useReducer } from "react";
import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";

if (window) {
  import("moment/locale/pt-br");
}

import WeekButtonGroup from "../layout/WeekButtonGroup";
import DefaultSpinner from "../layout/DefaultSpinner";
import NoEventMessage from "../layout/NoEventMessage";
import Icon from "../utils/Icon";
import {
  getToday,
  generateMatches,
  generateChampionships,
  desktopPrint,
  generateMatchTableContent,
  fetcher,
  getSportId,
} from "../../utils";
import Navbar from "../SportsPage/Navbar";

export default function PrintTable({
  axios,
  axiosNavbar,
  settings,
  isMobile,
  isDarkTheme,
  sport,
  onChangeSport,
  onItemClick,
}) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(getToday());
  const [allSelected, setAllSelected] = useState(false);
  const [championships, setChampionships] = useState([]);
  const [championshipsSelected, setChampionshipsSelected] = useState([]);

  const loadChampionships = () => {
    const data = {
      chave: "",
      tipo: "",
      data: selectedDate,
      esporte: getSportId(sport),
      principal: 1,
    };

    fetcher(axios, "/api/jogos", data)
      .then((result) => {
        if (result.data == null) {
          setChampionships([]);
          return;
        }

        const _matches = generateMatches(
          result.data,
          settings,
          false,
          selectedDate,
          getSportId(sport)
        );
        const _championships = generateChampionships(_matches, settings);
        setChampionships(_championships);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    loadChampionships();
    setChampionshipsSelected([]);
  }, [selectedDate]);

  useEffect(() => {
    if (allSelected) {
      const _championships = championships
        .map((c) => {
          return !verifyCheck(`${c.country}_${c.name}`)
            ? { id: `${c.country}_${c.name}`, ...c }
            : null;
        })
        .filter((c) => !!c)
        .concat(championshipsSelected);
      setChampionshipsSelected(_championships);
      forceUpdate();
    } else if (championshipsSelected.length > 0) setChampionshipsSelected([]);
  }, [allSelected]);

  useEffect(() => {
    const isAllSelected =
      championshipsSelected.length > 0 &&
      championshipsSelected.length === championships.length;
    setAllSelected(isAllSelected);
  }, [championships, championshipsSelected]);

  const verifyCheck = (id) => {
    return championshipsSelected.filter((c) => c.id === id).length > 0;
  };

  const handleCheck = (id) => {
    const isChecked = verifyCheck(id);

    if (isChecked)
      setChampionshipsSelected(
        championshipsSelected.filter((c) => c.id !== id)
      );
    else {
      const _championshipsSelected = championshipsSelected;
      const [country, name] = id.split("_");
      const _championship = championships.filter(
        (c) => c.country === country && c.name === name
      )[0];
      _championshipsSelected.push({ id, ..._championship });
      setChampionshipsSelected(_championshipsSelected);
    }
    forceUpdate();
  };

  const handlePrint = () => {
    if (championshipsSelected.length > 0) {
      const data = {
        day_week: moment(selectedDate).format("dddd"),
        date: moment(selectedDate).format("DD/MM/YYYY"),
        championships: championshipsSelected,
        message: "Acesse app.franquia.bet",
      };

      desktopPrint(generateMatchTableContent(data, sport, settings));
    }
  };

  const renderContent = () => {
    return (
      <ListGroup variant="flush" as="ul" className="list-scroll">
        {championships.map((c, index) => (
          <ListGroup.Item
            as="li"
            variant={isDarkTheme ? "dark" : ""}
            key={index}
          >
            <Form.Check
              type="checkbox"
              label={c.key}
              custom
              className="custom-checkbox"
              checked={verifyCheck(`${c.country}_${c.name}`)}
              id={`${c.country}_${c.name}`}
              onChange={() => handleCheck(`${c.country}_${c.name}`)}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const renderButtons = () => {
    return (
      <Row className="my-3">
        <Col>
          <Button
            variant="secondary"
            block
            onClick={() => setAllSelected(!allSelected)}
          >
            <Icon name={allSelected ? "minus-square" : "check-square"} margin />
            {allSelected ? "Des." : "Sel."} Tudo
          </Button>
        </Col>
        <Col>
          <Button variant="primary" block onClick={() => handlePrint()}>
            <Icon name="print" margin />
            Imprimir
          </Button>
        </Col>
      </Row>
    );
  };

  if (isMobile) {
    return (
      <div className="home-m-wrapper">
        <div className="scroll-mobile">
          <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} isDarkTheme={isDarkTheme} />
          <div className="m-3">
            {renderButtons()}
            {renderContent()}
          </div>
        </div>
      </div>
    );
  }

  const renderMain = () => {
    if (loading) return <DefaultSpinner isDarkTheme={isDarkTheme} />;

    if (!championships.length)
      return (
        <NoEventMessage
          showWeek
          isDarkTheme={isDarkTheme}
          settings={settings}
          selectedDate={selectedDate}
          onChange={setSelectedDate}
        />
      );

    return (
      <div className="m-3">
        <div className="w-100">
          <WeekButtonGroup
            settings={settings}
            selectedDate={selectedDate}
            onChange={setSelectedDate}
            isMobile={true}
            isDarkTheme={isDarkTheme}
            fullWidth={true}
          />
        </div>
        {renderButtons()}
        {renderContent()}
      </div>
    );
  };

  return (
    <>
      <Navbar active={sport} axios={axiosNavbar} onItemClick={onItemClick} onSelect={onChangeSport} isDarkTheme={isDarkTheme} />
      <div className="flex-column home-wrapper">{renderMain()}</div>
    </>
  );
}
