import { useEffect, useState } from "react";
import { Alert, Collapse } from "react-bootstrap";
import classnames from "classnames";
import styles from "../../styles/TopAlert.module.css";
import Icon from "../utils/Icon";

export default function TopAlert({ settings, isMobile }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (document) {
      const body = document.querySelector("body");

      if (isMobile) {
        body.classList.remove("overflow-hidden");
      }

      if (show && !isMobile) {
        window.history.scrollRestoration = "manual";
        body.classList.add("overflow-hidden");
      }
    }
  }, [show, isMobile]);

  return (
    <Alert
      transition={Collapse}
      variant="primary"
      show={show}
      onClick={() => setShow(false)}
      className={classnames("top-alert mb-0 text-center rounded-0", {
        "text-white": settings?.cor_texto_topo,
        "text-dark": !settings?.cor_texto_topo,
      })}
      style={{
        backgroundColor: settings.background_topo,
        border: "none",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          margin: 0,
          padding: 0,
          position: "relative",
        }}
      >
        {settings.mensagem_topo}
        <Icon
          name="times"
          className={`${
            settings?.cor_texto_topo ? "text-white" : "text-dark"
          } ${styles.CloseButton}`}
        />
      </div>
    </Alert>
  );
}
