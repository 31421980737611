import { useEffect, useState } from 'react'
import { Badge, Col, Container, Row } from 'react-bootstrap'

import SportMatchOdds from './SportMatchOdds'
import ColAuto from '../utils/ColAuto'
import Icon from '../utils/Icon'
import { fetcher, getLocalDate } from '../../utils'

export default function MatchList({ championship = null, isMobile, isLive = false, isDarkTheme, settings, timezone, sport, selectedOdds, onOddClick, onShowOddsClick }) {
  const categories = Array.from(new Set(championship.matches.sort((a, b) => a.cotacoes.length - b.cotacoes.length)[0].cotacoes.map(cotacao => +cotacao.cat_id)))
  const mainCategories = Object.values((settings.categorias_destaque && settings.categorias_destaque[sport]) || {}).filter(category => categories.includes(category.id))
  const [match,] = championship.matches
  const [mainCategory, setMainCategory] = useState(!isLive && mainCategories.length ? mainCategories[0].id : 1)
  const [showChampionship, setShowChampionships] = useState(true)

  useEffect(() => {
    if (isLive) {
      return
    }

    const i = setInterval(() => {
      const matchesInLive = championship.matches.every(m => getLocalDate(m.data) <= getLocalDate())

      if (matchesInLive) {
        clearInterval(i)
      }

      setShowChampionships(!matchesInLive)
    }, 1000)

    return () => {
      clearInterval(i)
    }
  }, [isLive, championship])

  if (!showChampionship) {
    return null
  }

  return (
    <>
      <Container fluid >
        <Row className="event-header">
          {isMobile && !isLive && mainCategories.length ? <>
            <ColAuto className="pr-1">
              <img src={championship.flag} className="align-middle" width="20" border="0" />
            </ColAuto>
            <Col className="ml-0 pl-2 pr-4 text-left text-truncate">
              <div className="d-flex flex-column">
                <span className="m-0 p-0 text-truncate" style={{ lineHeight: '1rem' }}>
                  {championship.country}
                </span>
                <small className="m-0 p-0 text-truncate" style={{ lineHeight: '1rem' }}>
                  {championship.name}
                </small>
              </div>
            </Col>
          </>
            :
            <Col className="pl-0 pr-4 text-left text-truncate">
              <img src={championship.flag} className="mx-2 align-middle" width="20" border="0" />
              <span>{championship.key}</span>
            </Col>
          }
          {!isMobile ? <ColAuto className="ml-auto text-right text-capitalize">
            {isLive ?
              <Badge variant="danger" className="live-blink mr-1">Live</Badge>
            :
            <>
              <Icon name="calendar-alt" /> {getLocalDate(match.data, timezone).format('dddd: DD MMMM YYYY')}
            </>
            }
          </ColAuto>
            :
            !isLive && !!mainCategories.length && <ColAuto className="pr-2 bg-parallelogram">
              <select className="form-control-sm" value={mainCategory} onChange={({ target }) => setMainCategory(target.value)}>
                {mainCategories.map(category => <option key={category.id} value={category.id}>{category.titulo}</option>)}
              </select>
            </ColAuto>
          }
          {!isMobile && championship.favorite > 0 && <ColAuto>
            <Icon style="fad" name="star" />
          </ColAuto>}
        </Row>
        {championship.matches.map(match => <SportMatchOdds
            key={match.id_partida}
            match={match}
            mainOdds={match.cotacoes.filter(odd => +odd.cat_id === +mainCategory).sort((a, b) => a.ordem_tipo - b.ordem_tipo)}
            selectedOdds={selectedOdds}
            onOddClick={onOddClick}
            onShowOddsClick={onShowOddsClick}
            isMobile={isMobile}
            isDarkTheme={isDarkTheme}
            timezone={settings.fuso_horario}
            sport={sport}
            settings={settings}
            live={isLive ? true : null}
          />
        )}
      </Container>
    </>
  )
}
