import classnames from "classnames";

import Icon from "components/utils/Icon";
import styles from "./index.module.css";

function SearchBy({
  queryName,
  setQueryName,
  setQueryProvider,
  queryProvider,
  providers,
  isDarkTheme
}) {
  return (
    <form className={styles.Wrapper} onSubmit={(e) => e.preventDefault()}>
      <label className={styles.Field} title="Pesquisar jogo">
        <Icon name="search" />
        <input
          type="text"
          name="searchByName"
          id="searchByName"
          placeholder="Pesquisar jogo"
          value={queryName}
          className={classnames({ 'bg-dark text-light': isDarkTheme })}
          onChange={({ target }) => setQueryName(target.value)}
        />
      </label>
      <label className={styles.Field} title="Selecionar Provedor">
        <Icon name="gamepad-alt" />
        <select
          name="searchByProvider"
          id="searchByProvider"
          defaultValue={0}
          className={classnames({ 'bg-dark text-light': isDarkTheme })}
          onChange={({ target }) => setQueryProvider(target.value)}
        >
          <option value={0}>
            {queryProvider !== 0 ? "Todos" : "Selecionar Provedor"}
          </option>
          {providers
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((provider) => (
              <option key={provider.id} value={provider.id}>
                {provider.name}
              </option>
            ))}
        </select>
      </label>
    </form>
  );
}

export default SearchBy;
