export default function MatchStopsStats({ yellowCards, redCards, corners, position = 'left' }) {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center w-25 pt-1">
        {position == 'right' ? <div className="d-flex flex-column justify-content-center align-items-center mr-1" style={{ width: '20px', height: '20px' }}>
          <img src={'/icons/redFlag.svg'} className="w-100" style={{ height: '13px', objectPosition: '3px' }} />
          <div className="text-center mt-1" style={{ fontSize: '13px', lineHeight: '15px', fontWeight: 700 }}>
            {corners}
          </div>
        </div>
          :
          <div className="d-flex flex-column justify-content-center align-items-center mr-1" style={{ width: '20px', height: '20px' }}>
            <img src={'/icons/yellowCard.svg'} />
            <div className="text-center mt-2" style={{ fontSize: '13px', lineHeight: '15px', fontWeight: 700 }}>
              {yellowCards}
            </div>
          </div>
        }
        <div className="d-flex flex-column justify-content-center align-items-center mr-1" style={{ width: '20px', height: '20px' }}>
          <img src={'/icons/redCard.svg'} />
          <div className="text-center mt-2" style={{ fontSize: '13px', lineHeight: '15px', fontWeight: 700 }}>
            {redCards}
          </div>
        </div>
        {position == 'right' ?
          <div className="d-flex flex-column justify-content-center align-items-center mr-1" style={{ width: '20px', height: '20px' }}>
            <img src={'/icons/yellowCard.svg'} />
            <div className="text-center mt-2" style={{ fontSize: '13px', lineHeight: '15px', fontWeight: 700 }}>
              {yellowCards}
            </div>
          </div>
          :
          <div className="d-flex flex-column justify-content-center align-items-center mr-1" style={{ width: '20px', height: '20px' }}>
            <img src={'/icons/redFlag.svg'} className="w-100" style={{ height: '13px', objectPosition: '3px' }} />
            <div className="text-center mt-1" style={{ fontSize: '13px', lineHeight: '15px', fontWeight: 700 }}>
              {corners}
            </div>
          </div>
        }
      </div>
    </>
  )
}
