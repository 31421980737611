import { useRef } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import classnames from 'classnames'

import Icon from '../utils/Icon'
import { fetcher } from '../../utils'

export default function RecoverPasswordModal({ show, isDarkTheme, settings, onHide, axios, setLoading, onError }) {
  if (window && show) {
    const body = window.document.querySelector("body");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }
  }

  const email = useRef(null)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (email.current == null || email.current.value == '') {
      return
    }

    setLoading(true)

    const body = { email: email.current.value }

    fetcher(axios, '/api/recuperar-senha', body)
      .then(({ data }) => {
        setLoading(false)

        if (!data.resposta) {
          alertify.alert(data.mensagem, data.descricao)

          return
        }

        alertify.alert('Sucesso', `Foi enviado um email para ${body.email} contendo um link para recuperar a sua senha`)
        onHide()
      }).catch(onError)
  }

  return (
    <Modal show={show} onHide={() => onHide()} centered>
      <Modal.Header className={classnames(['pt-2 pb-2', { 'bg-dark': isDarkTheme }])}>
        <Modal.Title className={classnames('display-5', { 'text-white': isDarkTheme })}>
          <Icon name="globe" margin />
          Recuperar senha
        </Modal.Title>
        <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames(['text-right mt-1', { 'text-white': isDarkTheme }])} onClick={onHide}>
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-flex h-100 p-3 flex-column align-self-center" onSubmit={handleSubmit}>
          <div className="pb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="at" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                ref={email}
                autoComplete="off"
                placeholder="Email"
                spellCheck={false}
                required={true}
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
              />
            </InputGroup>
          </div>
          <Button type="submit" variant="primary" block onClick={handleSubmit}>
            <Icon name="sign-in-alt" margin />
            Confirmar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
