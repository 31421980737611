import { useState } from 'react'
import { Form, InputGroup, Table } from 'react-bootstrap'
import classnames from 'classnames'

import Icon from '../utils/Icon'
import { formatNumber } from '../../utils'

export default function Cashier({
  isDarkTheme,
  type,
  data
}) {
  const [gambler, setGambler] = useState(null)

  if (type !== 'apostador') {
    if (gambler !== null)
      setGambler(null)

    return null
  }

  return (
    <>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
            <Icon name="user" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          as="select"
          className={classnames(['border-0 form-control', { 'bg-dark text-light': isDarkTheme }])}
          custom
          onChange={({target}) => setGambler(target.value)}
        >
          <option value="">Selecione apostador</option>
          {Object.keys(data).map((gambler) => 
            <option value={gambler} key={gambler}>
              {gambler}
            </option>)
          }
        </Form.Control>
      </InputGroup>
      <Table variant={isDarkTheme ? 'dark' : '' } striped className="rounded mt-3">
        <tbody>
          {gambler && <>
            <tr>
                <td>Apuração</td>
                <td>R$ {formatNumber(data[gambler].pre_jogo.valor_apostas)}</td>
            </tr>
            <tr>
                <td>Qtd. Apostas</td>
                <td>{data[gambler].pre_jogo.apostas}</td>
            </tr>
            <tr>
                <td>Qtd. Ganhadores</td>
                <td>{data[gambler].pre_jogo.ganhadores}</td>
            </tr>
            <tr>
                <td>Saldo em prêmios</td>
                <td>R$ {formatNumber(data[gambler].pre_jogo.premios)}</td>
            </tr>
            <tr>
                <td>Saldo em comissões</td>
                <td>R$ {formatNumber(data[gambler].pre_jogo.comissoes)}</td>
            </tr>
            <tr>
                <td>Saldo Líquido</td>
                <td>R$ {formatNumber(data[gambler].pre_jogo.saldo_liquido)}</td>
            </tr>
            <tr>
                <td colspan="12" className="bg-secondary">
                <Icon name="signal-stream" margin />
                Ao vivo
                </td>
            </tr>
            <tr>
                <td>Apuração</td>
                <td>R$ {formatNumber(data[gambler].ao_vivo.valor_apostas)}</td>
            </tr>
            <tr>
                <td>Qtd. Apostas</td>
                <td>{data[gambler].ao_vivo.apostas}</td>
            </tr>
            <tr>
                <td>Qtd. Ganhadores</td>
                <td>{data[gambler].ao_vivo.ganhadores}</td>
            </tr>
            <tr>
                <td>Saldo em prêmios</td>
                <td>R$ {formatNumber(data[gambler].ao_vivo.premios)}</td>
            </tr>
            <tr>
                <td>Saldo em comissões</td>
                <td>R$ {formatNumber(data[gambler].ao_vivo.comissoes)}</td>
            </tr>
            <tr>
                <td>Saldo Líquido</td>
                <td>R$ {formatNumber(data[gambler].ao_vivo.saldo_liquido)}</td>
            </tr>
          </>}
        </tbody>
      </Table>
    </>
  )
}
