import { useEffect, useState } from "react"


export default function ImageFallback({ src, fallBackImg, ...rest }) {
  const [imgSrc, setImgSrc] = useState(src !== null && src !== '' ? src : fallBackImg);

  useEffect(() => {
    if (src !== null && src !== '') {
      setImgSrc(src);
    }
  }, [src])

  return (
      <img
        {...rest}
        src={imgSrc}
        onLoad={({ target }) => {
          if (target.naturalWidth <= 1) {
            setImgSrc(fallBackImg);
          }
        }}
        onError={() => {
          setImgSrc(fallBackImg);
        }}
      />
  )
}