import DefaultSpinner from "../../layout/DefaultSpinner";
import InfiniteScroll from "react-infinite-scroll-component";

import GameCard from "../GameCard";
import styles from "./index.module.css";
import React from "react";

if (typeof window !== "undefined") {
  import("alertifyjs");
}

const Scroll = ({
  isMobile,
  isDarkTheme,
  games,
  user,
  settings,
  hasNextPage,
  setSelectedGame,
  setCurrentPage,
}) => {
  const handlePlay = (game) => {
    if (!user) {
      // @ts-ignore
      alertify.alert("Atenção", "Você precisa de uma conta para jogar.");
      //.set({ title: "Ops!" });
    } else if (user.nivel != 3) {
      // @ts-ignore
      alertify.alert("Cassino disponível apenas para apostadores.");
      //.set({ title: "Ops!" });
    } else {
      setSelectedGame(Object.assign({ type: "full" }, game));
    }
  };

  if (window && isMobile) {
    const body = window.document.querySelector("body");
    const next = window.document.querySelector("#__next");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }

    body.setAttribute("style", "overflow-y: scroll;");
    next.setAttribute("style", "overflow-y: scroll;");
  }

  return (
    <InfiniteScroll
      className={styles.Wrapper}
      dataLength={games.length}
      next={() => setCurrentPage((old: number) => old + 1)}
      hasMore={hasNextPage}
      loader={
        <div className={styles.Spinner}>
          <DefaultSpinner
            height={`${isMobile ? 8 : 10}vh`}
            isDarkTheme={isDarkTheme}
          />
        </div>
      }
      scrollableTarget={`${isMobile ? "body" : "page-wrapper"}`}
      style={{ overflowX: "hidden" }}
    >
      {games.map((game, index) => (
        <GameCard
          key={index}
          name={game.name}
          provider={game.provider_name}
          imgSrc={game.image || "./images/cassino/game.jpg"}
          hasDemo={settings.habilitar_demo}
          play={() => handlePlay(Object.assign({ type: "full" }, game))}
          demo={() => setSelectedGame(Object.assign({ type: "demo" }, game))}
        />
      ))}
    </InfiniteScroll>
  );
};

export default Scroll;
