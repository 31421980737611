import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'react-bootstrap';
import Icon from '../utils/Icon'
import { isSameMatchOdd } from '../../utils'

export default function OddButton({match, odd, main, label = null,  selectedOdds = [], onClick = () => {}, isMobile, isDarkTheme, arrowIcon = true, settings }) {
  const [firstOdd, setFirstOdd] = useState(0)
  const [changed, setChanged] = useState(false)
  const disabled = odd.cotacao <= settings.cotacao_minima_palpite

  useEffect(() => {
    setFirstOdd(odd.cotacao)
  }, [])

  useEffect(() => {
    if (firstOdd != 0 && !changed)
      setChanged(odd.cotacao != firstOdd)
  }, [odd])

  function oddLock() {
    return disabled ? <Icon name="lock" /> : (+odd.cotacao).toFixed(2);
  }

  const activeClass = () => {
    return !!selectedOdds.find(item => isSameMatchOdd(item, match) && item.odd.id_tipo === odd.id_tipo && (!!odd.jogador_id ? (item.odd.jogador_id === odd.jogador_id) : true))
  }

  const isUp = odd.cotacao > +odd.cotacao_anterior
  const isDown = odd.cotacao < +odd.cotacao_anterior

  const buttonClasses = {
    'active': activeClass(),
    'odd-up': changed && isUp,
    'odd-down': changed && isDown,
    'odd-blink': odd.mudanca
  }

  return (
    <Button variant={isDarkTheme ? 'black' : 'secondary'} className={classNames(buttonClasses)} block size={isMobile && main ? 'sm' : undefined} disabled={disabled} onClick={onClick}>
      {label ?
        <div className="d-flex justify-content-between">
          <div>{label}</div>
          <div>{oddLock()}</div>
        </div>
      : oddLock()
      }
    </Button>
  )
}
