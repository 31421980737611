import classnames from "classnames";

import Icon from '../../utils/Icon';
import styles from './index.module.css';

const handleWarnignsSection = () => {
  const warnigns = document?.getElementsByClassName(styles.Warnigns)[0];
  const paragraphs = document?.getElementsByClassName(
    styles.Paragraphs
  )[0] as HTMLDivElement;

  const classes = paragraphs.classList.value.split(' ');

  if (classes.includes(styles.Open)) {
    paragraphs.setAttribute('class', `${styles.Paragraphs}`);
    paragraphs.setAttribute('style', 'height: 3rem');
    warnigns.children[1].innerHTML = 'Ver Mais';
  } else {
    paragraphs.setAttribute('class', `${styles.Paragraphs} ${styles.Open}`);
    paragraphs.setAttribute('style', 'height: auto');
    warnigns.children[1].innerHTML = 'Ver Menos';
  }
};

const Footer = ({
  isDarkTheme,
  settings,
  apiUrl,
  pageName,
  showAttendance,
}) => {
  return (
    <footer className={classnames(styles.Wrapper, { "bg-dark text-light": isDarkTheme, "bg-white": !isDarkTheme })}>
      <img
        className={styles.Logo}
        src={settings.logo || `./images/logo${isDarkTheme ? '' : '-dark'}.png`}
        alt="Logo"
      />
      <div className={styles.TitleWrapper}>
        <div className={styles.IconWrapper}>
          <i className="fas fa-headset"></i>
          <h3 className={styles.Title}>Suporte</h3>
        </div>
        <nav className={classnames(styles.Navigation, { "bg-dark text-light": isDarkTheme })}>
          {settings.habilitar_atendimento ?
            <p className={classnames(styles.Anchor, { "text-light": isDarkTheme, "text-dark": !isDarkTheme })} onClick={() => showAttendance(settings)}>
              <Icon style="far" name="chevron-right" />
              Atendimento
            </p> : null
          }
          {/*<a className={`${styles.Anchor} ${txtColor}`} href="#">
            <Icon style="far" name="chevron-right" />
            Termos e Condições
          </a>
          <a className={styles.Anchor + txtColor} href="#">
            <Icon style="far" name="chevron-right" />
            Politica de Privacidade
          </a>

          <a
            <Icon style="far" name="chevron-right" />
            className={styles.Anchor + txtColor} href='#'
          >
            Blog
          </a>*/}
          {settings.nome_apk ? (
            <a
              className={classnames(styles.Anchor, { "text-light": isDarkTheme, "text-dark": !isDarkTheme })}
              href={
                (settings.nome_apk as string)?.includes('http') ? settings.nome_apk : `${apiUrl}/uploads/${settings.nome_apk}.apk`
              }
            >
              <Icon style="far" name="chevron-right" />
              Baixe nosso Aplicativo
            </a>
          ) : null}
        </nav>
      </div>
      <div className={styles.InfoWrapper}>
        <div className={styles.TitleWrapper}>
          <div className={styles.IconWrapper}>
            <i className="fas fa-wallet"></i>
            <h3 className={styles.Title}>Pagamento</h3>
          </div>
          <img
            className={styles.PaymentImg}
            src="./images/pix2.png"
            alt="Pix"
          />
        </div>
        <div className={styles.TitleWrapper}>
          <div className={styles.IconWrapper}>
            <i className="fas fa-user-plus"></i>
            <h3 className={styles.Title}>Siga-nos</h3>
          </div>
          <nav className={styles.SocialIcons}>
            {settings.whatsapp ? (
              <a
                className={classnames(styles.Anchor, { "text-light": isDarkTheme, "text-dark": !isDarkTheme })}
                onClick={() => showAttendance(settings)}
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            ) : null}
            {settings.instagram ? (
              <a
                className={classnames(styles.Anchor, { "text-light": isDarkTheme, "text-dark": !isDarkTheme })}
                href={`https://www.instagram.com/${settings.instagram}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fab fa-instagram"></i>
              </a>
            ) : null}
            {settings.youtube ? (
              <a
                className={classnames(styles.Anchor, { "text-light": isDarkTheme, "text-dark": !isDarkTheme })}
                href={`https://www.youtube.com/channel/${settings.youtube}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fab fa-youtube"></i>
              </a>
            ) : null}
          </nav>
        </div>
      </div>
      <section className={styles.Warnigns}>
        <div className={`${styles.Paragraphs} ${isDarkTheme ? 'dark-mode' : 'light-mode'}`}>
          <p>
            {`${pageName} não promove suas atividades para menores de 18 anos.
            Crianças devem sempre ter permissão dos pais antes de enviar qualquer
            informação pessoal (como nome, endereço de e-mail e número de
            telefone) pela internet, seja para qualquer pessoa ou para nós. Se
            você tem menos de 18 anos (ou idade inferior àquela legalmente
            permitida para fazer apostas em seu país de residência), solicitamos
            que não acesse o site ${pageName}. Jogar pode ser algo viciante.
            Jogue com responsabilidade. Para mais informações, visite nossa página
            Jogos Responsáveis.`}
          </p>
          <p>
            {`Ao navegar nesse site aceite o uso de certos cookies de navegador com
            o objetivo de melhorar a sua experiência. ${pageName} apenas usa
            cookies que melhoram a sua experiência e não interferem em sua
            privacidade. Por favor acesse à nossa Política de Privacidade para
            mais informação em relação à forma como utilizamos cookies e como pode
            desativar ou gerenciar os mesmos, caso queira.`}
          </p>
        </div>
        <button type="button" onClick={handleWarnignsSection}>
          Ver Mais
        </button>
      </section>
    </footer>
  );
};

export default Footer;
