import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import Icon from "../utils/Icon";

export default function Popup({ settings, isDarkTheme, defaultShow = true }) {
  const [show, setShow] = useState(defaultShow);

  if (window && show) {
    const body = window.document.querySelector("body");
    const next = window.document.querySelector("#__next");

    body.className += isDarkTheme ? ' dark-mode' : ' light-mode';
    body.setAttribute("style", "overflow-y: hidden;");
    next.setAttribute("style", "overflow-y: hidden;");
  }

  useEffect(() => {
    setShow(defaultShow);
  }, [defaultShow]);

  const onHide = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      style={{ maxHeight: "100vh" }}
      centered
      contentClassName="align-items-center mx-auto w-auto"
    >
      <a
        className="position-absolute text-white mt-1 mr-2"
        style={{ right: 0, cursor: "pointer" }}
        onClick={() => onHide()}
      >
        <Icon name="times" />
      </a>
      <img src={`${settings.popup}`} className="img-responsive" alt="Banner" />
    </Modal>
  );
}
