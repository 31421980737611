import classnames from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import Icon from '../utils/Icon'
import WeekButtonGroup from './WeekButtonGroup'

export default function NoEventMessage({ isDarkTheme, settings = null, showWeek = false, selectedDate = null, onChange = () => {}}) {
  return (
    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: '50vh' }}>
      <Container>
        <Row>
          <Col className={classnames(['p-0', { 'text-light': isDarkTheme }])}>
            <Icon name="fa-exclamation-circle" margin />
            Nenhum evento disponível!
          </Col>
        </Row>
        {showWeek && <Row>
          <Col className="mt-3 p-0">
            <WeekButtonGroup className="w-100" settings={settings} selectedDate={selectedDate} onChange={onChange} isMobile={true} isDarkTheme={isDarkTheme} />
          </Col>
        </Row>}
      </Container>
    </div>
  )
}
