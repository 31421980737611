import Icon from '../utils/Icon'

export default function NoInternetMessage({ isDarkTheme }) {
  return (
    <div className="d-flex justify-content-center align-items-center text-center" style={{height: '50vh'}}>
      <div className={isDarkTheme ? 'text-light' : ''}>
        <Icon name="exclamation-circle" margin />
        Por favor, verifique sua conexão de rede e tente novamente!
      </div>
    </div>
  )
}
