import { useState, useEffect } from 'react'
import { Button, Col, Form, InputGroup, Table, Row } from 'react-bootstrap'
import classnames from 'classnames'
import { getToday, formatNumber, fetcher } from '../../utils'
import Icon from '../utils/Icon'

export default function ExtractTable({
  user,
  isMobile,
  setLoading,
  axios,
  onError,
  settings,
  isDarkTheme
}) {
  const today = getToday()

  const [startDate, setStartDate] = useState(today)
  const [finalDate, setFinalDate] = useState(today)
  const [showPreviousBalance, setShowPreviosBalance] = useState(true)
  const [extract, setExtract] = useState({})

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(true)
    const data = {
      inicio: startDate,
      fim: finalDate,
      saldo_anterior: +showPreviousBalance
    }

    fetcher(axios, '/api/extrato', data)
      .then(({data}) => {
        setLoading(false)
        if (data) setExtract(data)
      }).catch(onError)
  }

  const renderTable = (data) => {
    let rows;

    if (user.nivel == 3) {
      rows = {
        'Depósitos': data.creditos,
        'Saques': data.debitos,
        'Apostas em Aberto': data.entradas_aberto,
        'Apostas (Esportes)': data.entradas,
        'Prêmios (Esportes)': data.saidas,
      }

      if (data.cassino) {
        rows['Apostas (Cassino)'] = data.cassino.entradas
        rows['Prêmios (Cassino)'] = data.cassino.saidas
      }

      if (+data.comissoes > 0) {
        rows['Cashback'] = data.comissoes
      }

      if (+data.indicacoes > 0) {
        rows['Indicações']= data.indicacoes
      }
    } else {
      rows = {
        'Entradas': data.entradas,
        'Em Aberto': data.entradas_aberto,
        'Saídas': data.saidas,
        'Comissões': data.comissoes,
        'Créditos': data.creditos,
        'Débitos': data.debitos,
      }
    }

    return (
      <>
        {Object.keys(rows).map((row, i) => (
          <tr key={i}>
            <td>{row}</td>
            <td>R$ {formatNumber(rows[row])}</td>
          </tr>
        ))}
      </>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                <Icon name="calendar" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
              type="date"
              value={startDate}
              onChange={({target}) => setStartDate(target.value)}
            />
          </InputGroup>
        </Col>
        {isMobile && <div className="w-100 mt-2"></div>}
        <Col>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                <Icon name="calendar" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
              type="date"
              value={finalDate}
              onChange={({target}) => setFinalDate(target.value)}
            />
          </InputGroup>
        </Col>
        {isMobile && <div className="w-100 mt-2"></div>}
        <Col>
          <Form.Group className="mt-1" controlId="showPreviousBalance">
            <Form.Check
              checked={showPreviousBalance}
              className={classnames({ 'text-light': isDarkTheme })}
              type="checkbox"
              label="Usar Saldo Anterior"
              onChange={(e) => setShowPreviosBalance(e.target.checked)} />
          </Form.Group>
        </Col>
        {isMobile && <div className="w-100 mt-2"></div>}
        <Col>
          <Button variant="secondary" block onClick={() => loadData()}>
            <Icon name="search" margin />
            Buscar
          </Button>
        </Col>
      </Row>
      <Table variant={isDarkTheme ? 'dark' : '' } striped className="rounded mt-3">
        <tbody>
          {renderTable(extract)}
          {showPreviousBalance && <tr>
            <td>Saldo Anterior</td>
            <td>R$ {formatNumber(extract.saldo_anterior)}</td>
          </tr>}
          <tr>
            <td>Saldo</td>
            <td>R$ {formatNumber(extract.saldo)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
