export default function MatchKicksChart({ data, label, colors = [], settings }) {
  const [home, away] = data
  const total = (+home) + (+away)
  const homePercent = (home / total) * 100
  const [homeColor, awayColor] = colors.length ? colors : [settings.cor_primaria, settings.cor_secundaria]

  return (
    <>
      <div className="px-1 w-100">
        <h4 className="text-center m-0" style={{ fontSize: '11px', lineHeight: '13px', fontWeight: 400 }}>
          {label}
        </h4>
        <div className="d-flex justify-content-center align-items-center">
          <b className="pr-1" style={{ fontSize: '13px', fontWeight: 700, lineHeight: '15px' }}>
            {home}
          </b>
          <div style={{ height: '3px', backgroundColor: awayColor, borderRadius: '2px', flex: '1 1 auto' }}>
            <span className="d-block" style={{ width: `${homePercent}%`, borderTopRightRadius: homePercent == 100 ? '2px' : '0px', borderBottomRightRadius: homePercent == 100 ? '2px' : '0px', backgroundColor: homeColor, height: '3px', borderRight: '1px solid var(--primary_text)' }} />
          </div>
          <b className="pl-1" style={{ fontSize: '13px', fontWeight: 700, lineHeight: '15px' }}>
            {away}
          </b>
        </div>
      </div>
    </>
  )
}
