import { Button, Container, Row, Col, Badge } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import moment from 'moment-timezone'

import OddButton from '../layout/OddButton'
import ColAuto from '../utils/ColAuto'
import Icon from '../utils/Icon'
import {
  showCrest, isSameMatchOdd, getLocalDate, getNameInitials, isMainOdd,
  padStart
} from '../../utils'
import ImageFallback from '../utils/ImageFallback'

export default function SportMatchOdds({ match, mainOdds, selectedOdds, onOddClick, onShowOddsClick, live = null, isMobile, isDarkTheme, timezone, sport, settings }) {
  const [timeLeft, setTimeLeft] = useState(null)
  const [needBlink, setNeedBlink] = useState(false)

  useEffect(() => {
    if (live) return

    const i = setInterval(() => {
      const start = getLocalDate(moment().format('YYYY-MM-DD HH:mm:ss'))
      const duration = moment.duration(getLocalDate(match.data, timezone).diff(start))
      const minutes = duration.asMinutes()
      const seconds = duration.asSeconds()

      if (minutes < 60) {
        let time = minutes < 1 ? seconds : minutes

        if (minutes <= 5)
          setNeedBlink(true)

        setTimeLeft(padStart(Math.floor(time)))
      }

      if (Math.floor(seconds) <= 0) {
        clearInterval(i)
      }
    }, 1000)

    return () => clearInterval(i)
  }, [match])

  const otherOddsActiveClass = (match) => {
    return selectedOdds.find(
      item => isSameMatchOdd(item, match) && !isMainOdd(item.odd)
    ) ? 'active' : ''
  }

  const renderMainOddButtons = (match) => {
    if (live || !mainOdds.length) {
      return (
        <>
          {[
            { label: 'C', odd: match.homeOdd },
            { label: 'E', odd: (sport == 1 && match.drawOdd) },
            { label: 'F', odd: match.awayOdd }
          ].map(obj =>
            obj.odd && <Col className="pl-2 pr-0" key={obj.odd.id_cotacao}>
              <OddButton
                main
                label={sport === 3 || sport === 6 ? getNameInitials(obj.odd.tipo) : obj.label}
                match={match}
                odd={obj.odd}
                selectedOdds={selectedOdds}
                onClick={() => onOddClick(match, obj.odd, live)}
                isMobile={isMobile}
                isDarkTheme={isDarkTheme}
                settings={settings}
              />
            </Col>)}
          {(sport != 3 && sport != 4 && sport != 6) &&
            <ColAuto className="pl-2 pr-0 mr-2">
              <Button
                block
                variant={isDarkTheme ? 'black' : 'secondary'}
                size={isMobile ? 'sm' : undefined}
                className={classnames([otherOddsActiveClass(match)])}
                onClick={() => onShowOddsClick(match)}
              >
                + {match.total_cotacoes || match.cotacoes.length}
              </Button>
            </ColAuto>
          }
        </>
      )
    }


    return (
      <>
        {mainOdds.map(odd => <Col key={`${match.id_partida}-${odd.cat_id}-${odd.id_tipo}`} className="pl-2 pr-0">
          <OddButton
            main
            label={sport === 3 || sport === 6 ? getNameInitials(odd.tipo) : (odd.abreviacao ? odd.abreviacao.toString().replace(' ', '') : getNameInitials(odd.tipo))}
            match={match}
            odd={odd}
            selectedOdds={selectedOdds}
            onClick={() => onOddClick(match, odd, live)}
            isMobile={isMobile}
            isDarkTheme={isDarkTheme}
            settings={settings}
          />
        </Col>)}
        {(sport != 3 && sport != 4 && sport != 6) &&
          <ColAuto className="pl-2 pr-0 mr-2">
            <Button
              block
              variant={isDarkTheme ? 'black' : 'secondary'}
              size={isMobile ? 'sm' : undefined}
              className={classnames([otherOddsActiveClass(match)])}
              onClick={() => onShowOddsClick(match)}
            >
              + {match.total_cotacoes || match.cotacoes.length}
            </Button>
          </ColAuto>
        }
      </>
    )
  }

  const renderScores = (match) => {
    const mandante = match.placar_mandante === null ? 0 : match.placar_mandante
    const visitante = match.placar_visitante === null ? 0 : match.placar_visitante

    return `${mandante} - ${visitante}`
  }

  const renderTime = (match) => {
    let time = ' · '

    if (match.tempo) {
      time += match.tempo;
    }
    else {
      if (match.tempo_jogo < 45) {
        time += '1T'
      }
      else if (match.tempo_jogo > 45) {
        time += '2T'
      }
      else {
        time += 'INT'
      }
    }
    return time;
  }

  if (!live && timeLeft !== null && +timeLeft <= 0) {
    return null
  }

  const renderStreamIcons = () => {
    if (isMobile) {
      if (match.tvstream) {
        return <Icon
          name="tv-retro"
          className={classnames({ 'text-light': isDarkTheme, 'mt-2': !match.estatisticas })}
        />
      }

      if (match.livestream) {
        return <img
          src={`/icons/football-field-${isDarkTheme ? 'light' : 'dark'}.svg`}
          width="18"
          className={classnames({ 'mt-2': !match.estatisticas })}
        />
      }
    }

    if (match.tvstream) {
      return <Icon
        name="tv-retro"
        className={classnames('my-4', { 'text-light': isDarkTheme })}
      />
    }

    if (match.livestream) {
      return <img
        src={`/icons/football-field-${isDarkTheme ? 'light' : 'dark'}.svg`}
        width="18"
        className={classnames('my-4')}
      />
    }

    return null
  }

  if (isMobile) {
    return (
      <>
        <Row className='matchlist py-2 justify-content-between'>
          <Col xs={8} className="pl-0 py-2">
            <div className={classnames('ml-2 text-truncate', { 'text-light': isDarkTheme })}>
              {showCrest(sport) &&
                <ImageFallback
                  className='mr-2 mb-1 align-middle'
                  src={match.escudo_mandante}
                  fallBackImg={'/images/shields/shield.png'}
                  width={20}
                  height={20}
                />
              }
              {match.mandante}
            </div>
            <div className={classnames('ml-2 text-truncate', { 'text-light': isDarkTheme })}>
              {showCrest(sport) &&
                <ImageFallback
                  className='mr-2 mb-1 align-middle'
                  src={match.escudo_visitante}
                  fallBackImg={'/images/shields/shield.png'}
                  width={20}
                  height={20}
                />
              }
              {match.visitante}
            </div>
          </Col>
          <Col xs={4} className="d-flex pr-2">
            {<Col xs={1} className="my-2 pl-0">
              {renderStreamIcons(match)}
              {match.estatisticas && <Icon
                name="chart-area"
                className={classnames({ 'text-light': isDarkTheme, 'mt-2': !match.tvstream && !match.livestream })}
              />}
            </Col>}
            <Col xs={live ? 10 : 11} className="pt-2 px-0 d-flex flex-column align-items-end">
              {live ?
                <>
                  <div className={classnames('text-truncate', { 'text-light': isDarkTheme })}>
                    <Badge variant="danger" className="live-blink mr-1">Live</Badge>
                    {renderScores(match)}
                  </div>
                  <div className={classnames({ 'text-light': isDarkTheme })}>
                    <Icon style="far" name="clock" /> {padStart(match.tempo_jogo)}
                    {renderTime(match)}
                  </div>
                </>
                :
                <>
                  <div className={classnames('pr-2 text-right text-truncate', { 'text-light': isDarkTheme })}>
                    <Icon name="calendar-alt" /> {getLocalDate(match.data, timezone).format('DD/MM')}
                  </div>
                  <div className={classnames('pr-2 text-right text-truncate', { 'text-light': isDarkTheme })}>
                    <Icon style="far" name="clock" /> {getLocalDate(match.data, timezone).format('HH:mm')}
                  </div>
                </>
              }
            </Col>
          </Col>
          <Col xs={12} className="d-flex px-0">
            {renderMainOddButtons(match)}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Row className='matchlist'>
      <Col className="pl-0 pt-2 pb-2">
        <div className={classnames('pl-2 text-truncate', { 'text-light': isDarkTheme })}>
          {showCrest(sport) &&
            <ImageFallback
              className='mr-2 mb-1 align-middle'
              src={match.escudo_mandante}
              fallBackImg={'/images/shields/shield.png'}
              width={20}
              height={20}
            />
          }
          {match.mandante}
        </div>
        <div className={classnames('pl-2 text-truncate', { 'text-light': isDarkTheme })}>
          {showCrest(sport) &&
            <ImageFallback
              className='mr-2 mb-1 align-middle'
              src={match.escudo_visitante}
              fallBackImg={'/images/shields/shield.png'}
              width={20}
              height={20}
            />
          }
          {match.visitante}
        </div>
      </Col>
      {live && <Col xs={1} className="p-0 text-right">
        {renderStreamIcons(match)}
        {match.estatisticas && <Icon
          name="chart-area"
          className={classnames('my-4', { 'text-light': isDarkTheme, 'ml-3': match.tvstream || match.livestream })}
        />}
      </Col>}
      <ColAuto className='pl-0 py-2'>
        <div className={classnames('pl-2 text-truncate', { 'text-light': isDarkTheme })}>
          {sport == 1 && <Badge variant="warning" className="ml-1 text-right">
            {match.placar_mandante === null ?
              '0'
              :
              match.placar_mandante
            }
          </Badge>}
        </div>
        <div className={classnames('pl-2 text-truncate', { 'text-light': isDarkTheme })}>
          {sport == 1 && <Badge variant="warning" className="ml-1 text-right">
            {match.placar_visitante === null ?
              '0'
              :
              match.placar_visitante
            }
          </Badge>}
        </div>
      </ColAuto>
      <Col className="mr-2 m-top-13">
        <Row>
          <ColAuto className={classnames('text-truncate top-7 pr-0', { 'text-light': isDarkTheme })}>
            <Icon style="far" name="clock" margin />
            {live ?
              <>
                {padStart(match.tempo_jogo)}
                {match.tempo && ` · ${match.tempo}`}
              </>
              :
              <span className={timeLeft && classnames({ 'diff-blink': needBlink, 'diff': !needBlink })}>
                {timeLeft ?
                  <>
                    00:{padStart(timeLeft)}
                  </>
                  :
                  getLocalDate(match.data, timezone).format('HH:mm')
                }
              </span>
            }
          </ColAuto>
          {renderMainOddButtons(match)}
        </Row>
      </Col>
    </Row>
  )
}
