import { Carousel } from "react-bootstrap";

export default function BannersCarrousel({ banners, setPage = null, setSport = null }) {
  if (banners?.length === 0) return null;

  return (
    <Carousel interval={4000}>
      {banners?.length > 0 &&
        banners?.map((banner) => (
          <Carousel.Item
            key={banner.id}
            style={banner.page ? { cursor: "pointer" } : null}
            onClick={() => {
              if (banner.page) {
                setPage("sports");
                setSport(banner.page);
              }
            }}
          >
            <img src={banner.caminho} className="img-responsive" alt="Banner" />
          </Carousel.Item>
        ))}
    </Carousel>
  );
}
