import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import classnames from "classnames";
import DefaultSpinner from "../layout/DefaultSpinner";

import styles from "../../styles/GameModal.module.css";
import Icon from "../utils/Icon";
import { fetcher } from "../../utils";

export default function CasinoGameModal({
  game,
  axios,
  isMobile,
  isDarkTheme,
  setSelectedGame,
  refreshBalance,
}) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    switch (game?.type) {
      case "demo":
        setUrl(`https://painel.franquia.bet/api/casino/demo/${game?.id}`);
        return null;
      case "full":
        fetcher(axios, `/api/iniciar_jogo?jogo=${game?.id}`).then(
          ({ data }) => {
            if (!data?.resposta) {
              alertify.alert(data?.mensagem, data?.descricao);
              setSelectedGame(null);
            } else {
              setUrl(data?.url);
            }
          }
        );
        return null;
      default:
        return null;
    }
  }, [game]);

  const handleHide = () => {
    setSelectedGame(null);

    if (game?.type !== "demo") {
      refreshBalance()
    }
  };

  return (
    <Modal
      show={game !== null}
      size="xl"
      dialogClassName="my-0 modal-90w"
      onHide={() => handleHide()}
    >
      <Modal.Header>
        <Modal.Title className={classnames({ "text-white": isDarkTheme })}>
          {game &&
            (game.name.includes("Mobile")
              ? game.name.replace(" Mobile", "")
              : game.name)}
        </Modal.Title>
        <Button
          variant={isDarkTheme ? "dark" : "light"}
          className={classnames("text-right mt-1", {
            "text-white": isDarkTheme,
          })}
          onClick={() => handleHide()}
        >
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <div className={styles.Spinner}>
          <DefaultSpinner
            height={`${isMobile ? 8 : 10}vh`}
            isDarkTheme={isDarkTheme}
          />
        </div>
        <iframe
          onLoad={() => {
            const spinner = document?.getElementsByClassName(styles.Spinner)[0];
            const iframe = document?.getElementsByClassName(
              styles.CasinoGame
            )[0];

            spinner.setAttribute("style", "display: none;");
            iframe.setAttribute("style", "display: block;");
          }}
          className={styles.CasinoGame}
          src={url}
        ></iframe>
      </Modal.Body>
    </Modal>
  );
}
