export default function MatchWheelChart({ data, label, colors = [], settings }) {
  const [home, away] = data
  const total = (+home) + (+away)
  const awayPercent = away / total
  const awayWidth = ((2 * Math.PI * 16) - 20) * awayPercent
  const [homeColor, awayColor] = colors.length ? colors : [settings.cor_primaria, settings.cor_secundaria]

  return (
    <>
      <div className="d-flex flex-column">
        <div
          className="text-break overflow-hidden text-center"
          style={{ lineHeight: '13px', maxHeight: '26px', fontSize: '11px' }}
        >
          {label}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-1">
          <div className="pr-1">{home}</div>
          <svg
            className="d-block"
            width="32"
            height="32"
            viewBox="1 0 29 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                strokeWidth="5"
                d="M16,28.5c6.904,0,12.5-5.597,12.5-12.5c0-6.904-5.596-12.5-12.5-12.5C9.098,3.5,3.5,9.096,3.5,16C3.5,22.903,9.098,28.5,16,28.5z"
                stroke={homeColor}
              />
              <path
                strokeWidth="5"
                d="M16,28.5c6.904,0,12.5-5.597,12.5-12.5c0-6.904-5.596-12.5-12.5-12.5C9.098,3.5,3.5,9.096,3.5,16C3.5,22.903,9.098,28.5,16,28.5z"
                transform="rotate(-3.708489181521415 16 16)"
                style={{ stroke: 'var(--primary_text)', strokeDasharray: `78.5508px, ${80 - (awayPercent * 100) + 7.5508 + (awayWidth >= 50 ? 2 : 1)}`, strokeDashoffset: 80 - awayWidth }}
              />
              <path
                strokeWidth="5"
                d="M16,28.5c6.904,0,12.5-5.597,12.5-12.5c0-6.904-5.596-12.5-12.5-12.5C9.098,3.5,3.5,9.096,3.5,16C3.5,22.903,9.098,28.5,16,28.5z"
                stroke={awayColor}
                style={{ strokeDasharray: '78.5508px, 78.5508px', strokeDashoffset: 80 - awayWidth }}
              />
            </g>
          </svg>
          <div className="pl-1">{away}</div>
        </div>
      </div>
    </>
  )
}
