import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group';

import ImageFallback from '../utils/ImageFallback'
import MatchStopsStats from '../sports/MatchStopsStats'
import MatchWheelChart from '../charts/MatchWheelChart'
import MatchKicksChart from '../charts/MatchKicksChart'
import Icon from '../utils/Icon'
import {
  showCrest, getLocalDate, padStart
} from '../../utils'

export default function OddsModalHeader({ match, settings, isLive }) {
  const [showStatistics, setShowStatistics] = useState(match && !match.livestream && !match.tvstream);

  const renderTime = (match) => {
    if (match.tempo) {
      if (match.tempo == 'INT')
        return 'INTERVALO'
      return match.tempo
    }

    if (+match.tempo_jogo < 45)
      return '1T';

    if (+match.tempo_jogo > 45)
      return '2T';

    return 'INTERVALO'
  }

  const renderStatistics = (match) => {
    if (!match || !match.estatisticas) {
      return null
    }

    return (
      <CSSTransition
        in={showStatistics}
        timeout={800}
        classNames="statistics"
        unmountOnExit
      >
        <Row>
          <Col xs={12} className="py-3 d-flex justify-content-around">
            {match.estatisticas.attacks && <MatchWheelChart
              data={match.estatisticas.attacks}
              label="Ataques"
              colors={[match.cor_mandante, match.cor_visitante]}
              settings={settings}
            />}
            {match.estatisticas.dangerous_attacks && <MatchWheelChart
              data={match.estatisticas.dangerous_attacks}
              label="Ataques Perigosos"
              colors={[match.cor_mandante, match.cor_visitante]}
              settings={settings}
            />}
            {match.estatisticas.possession_rt && <MatchWheelChart
              data={match.estatisticas.possession_rt}
              label="% de Posse"
              colors={[match.cor_mandante, match.cor_visitante]}
              settings={settings}
            />}
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-center align-items-center">
              {match.estatisticas.yellowcards && match.estatisticas.redcards && match.estatisticas.corners && <MatchStopsStats
                yellowCards={match.estatisticas.yellowcards[0]}
                redCards={match.estatisticas.redcards[0]}
                corners={match.estatisticas.corners[0]}
              />}
              <div style={{ fontSize: '11px', width: '50%' }}>
                {match.estatisticas.on_target && <MatchKicksChart
                  data={match.estatisticas.on_target}
                  label="No Alvo"
                  colors={[match.cor_mandante, match.cor_visitante]}
                  settings={settings}
                />}
                {match.estatisticas.off_target && <MatchKicksChart
                  data={match.estatisticas.off_target}
                  label="Ao Lado"
                  colors={[match.cor_mandante, match.cor_visitante]}
                  settings={settings}
                />}
              </div>
              {match.estatisticas.yellowcards && match.estatisticas.redcards && match.estatisticas.corners && <MatchStopsStats
                yellowCards={match.estatisticas.yellowcards[1]}
                redCards={match.estatisticas.redcards[1]}
                corners={match.estatisticas.corners[1]}
                position="right"
              />}
            </div>
          </Col>
        </Row>
      </CSSTransition>
    )
  }

  return (
    <Container>
      <Row>
        <Col className="text-truncate">
          <div className="d-flex flex-column align-items-center">
            {showCrest(match.id_esporte) &&
              <div>
                <ImageFallback
                  className='mr-2 mb-1 align-middle object-fit'
                  src={match.escudo_mandante}
                  fallBackImg={'/images/shields/shield.png'}
                  width={48}
                  height={48}
                  border="0"
                />
              </div>
            }
            <div className="text-truncate w-150 text-light">{match.mandante}</div>
          </div>
        </Col>
        <Col className="text-truncate">
          <div className="d-flex flex-column align-items-center mt-2">
            <div className="text-light">{isLive ? `${match.placar_mandante === null ? '0' : match.placar_mandante} - ${match.placar_visitante === null ? '0' : match.placar_visitante}` : 'HORÁRIO'}</div>
            <div className="text-light">{isLive ? <>
              {padStart(match.tempo_jogo)}
              <span className="live-blink">'</span> - {renderTime(match)}
            </>
              : getLocalDate(match.data, settings.timezone).format('DD/MM HH:mm')}
            </div>
            {isLive && match.estatisticas != null &&
              <small>
                <a onClick={() => setShowStatistics(!showStatistics)}
                  className="statistics-link">
                  {showStatistics ? 'Esconder' : 'Ver'} Estatisticas
                  {showStatistics ? <Icon name="caret-up" /> : <Icon name="caret-down" />}
                </a>
              </small>
            }
          </div>
        </Col>
        <Col className="text-truncate">
          <div className="d-flex flex-column align-items-center">
            {showCrest(match.id_esporte) &&
              <div>
                <ImageFallback
                  className='mr-2 mb-1 align-middle object-fit'
                  src={match.escudo_visitante}
                  fallBackImg={'/images/shields/shield.png'}
                  width={48}
                  height={48}
                  border="0"
                />
              </div>
            }
            <div className="text-truncate w-150 text-light">{match.visitante}</div>
          </div>
        </Col>
      </Row>
      {renderStatistics(match)}
    </Container>
  )
}
