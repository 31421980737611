import {useEffect, useState} from 'react';
import classnames from 'classnames';

import Icon from '../../utils/Icon';
import {fetcher} from "../../../utils";
// @ts-ignore
import styles from './index.module.css';

const COLORED_ICONS = {
  'Futebol': '/icons/soccer.svg',
  'Basquete': '/icons/basketball.svg',
  'live': '/icons/live.svg',
  'Vôlei': '/icons/volley.svg',
  'E-Sports': '/icons/esports.svg',
  'Tênis': '/icons/tennis.svg',
  'Lutas': '/icons/boxing.svg',
  'casino': '/icons/casino.svg'
}

const MONO_ICONS = {
  'Futebol': 'futbol',
  'Basquete': 'basketball-hoop',
  'live': 'signal-stream',
  'Vôlei': 'volleyball-ball',
  'E-Sports': 'gamepad-alt',
  'Tênis': 'tennis-ball',
  'Lutas': 'boxing-glove',
  'casino': 'dice'
}

interface NavbarProps {
  axios: any;
  active: string;
  isDarkTheme: boolean;
  onItemClick: (item: string) => {};
  onSelect: any;
}

const Navbar = ({
   axios,
   active,
   onSelect,
   onItemClick,
   isDarkTheme
 }: NavbarProps) => {
  const [loading, setLoading] = useState(true);
  const [sports, setSports] = useState([]);
  const [hasCasino, setHasCasino] = useState(false);
  const [hasLive, setHasLive] = useState(false);
  const [isColored, setIsColored] = useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      fetcher(axios, "/api/config")
        .then(({ data }) => {
          setSports(json2array(data?.esportes));
          setHasCasino(data?.modulo_cassino && data?.habilitar_cassino);
          setHasLive(data?.habilitar_ao_vivo);
          setIsColored(data?.icones_coloridos);
          setLoading(false);
        })
    }
    loadSettings();
  }, []);

  useEffect(() => {
    active === 'casino-live' ? handleActiveCategory('casino') : handleActiveCategory(active);
  }, [active, loading]);

  const handleActiveCategory = (category: string) => {
    if (window) {
      const otherItems = window?.document?.querySelectorAll(`:not(#${category})`);
      otherItems.forEach((item: HTMLLIElement) => item.className = item.className.replace(`${styles.Active}`, ''));

      const activeItem = window?.document?.querySelector(`#${category}`);
      if (activeItem) {
        (activeItem as HTMLLIElement).className += `${styles.Active}`
      }
    }
  }

  const handleClick = (key: string) => {
    onSelect(key);
    onItemClick('sports');
  }

  function json2array(json){
    var result = [];
    var keys = Object.keys(json);
    keys.forEach(function(key){
      result.push(json[key]);
    });
    return result;
  }

  return !loading ? (
    <nav className={classnames(styles.Navigation, 'scrollable', { 'dark bg-brown': isDarkTheme, 'light bg-light-gray': !isDarkTheme })}>
      <ul className={styles.Wrapper}>
        {hasCasino ? (
          <li key={'casino'} id={'casino'} onClick={() => handleClick('casino')}>
            {isColored
             ? <img src={COLORED_ICONS['casino']} alt="Cassino" />
             : <Icon name={MONO_ICONS['casino']} />}
            <span className={classnames({ 'text-light': isDarkTheme })}>Cassino</span>
          </li>
        ) : null}

        {hasLive ? (
          <li key={'live'} id={'live'} onClick={() => handleClick('live')}>
            {isColored
             ? <img src={COLORED_ICONS['live']} alt="Ao vivo" />
             : <Icon name={MONO_ICONS['live']} />}
            <span className={classnames({ 'text-light': isDarkTheme })}>Ao vivo</span>
          </li>
        ) : null}

        {sports?.map(({name, key}) => (
          <li key={key} id={key} onClick={() => handleClick(key)}>
            {isColored
             ? <img src={COLORED_ICONS[name]} alt={name}/>
             : <Icon name={MONO_ICONS[name]}/>}
            <span className={classnames({ 'text-light': isDarkTheme })}>{name}</span>
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
}

export default Navbar;
